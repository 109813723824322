import consumer from "./consumer"

consumer.subscriptions.create({ channel: "PredictDocumentAsyncChannel" }, {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    const statusElement = document.querySelector(`#job-status-${data.job_id}`);
    if (statusElement) {
      if (data.status === "completed") {
        statusElement.classList.remove('pending');
        $(statusElement).find('.status_complete').removeClass('hidden')
        $(statusElement).find('.status_waiting').addClass('hidden')
        $(statusElement).find('.retry_link_v2').addClass('hidden')
        statusElement.classList.add('complate');
        const openLinkElement = statusElement.querySelector('.open_link_v2');
        if (openLinkElement) {
          openLinkElement.dataset.url = data.url
        } else {
          console.warn("Link with .open_link_v2 not found within statusElement.");
        }
      } else if (data.status === "failed") {
        // if status is failed remove node
        statusElement.remove()
      }
    }
  },
});
